.moon{
    display: flex;
    flex-direction: row;
    color:gray;
    text-align: center;
    justify-content: center;

}

.box{
    padding: 7%;
}
.moon:hover{
    transform: none;
}
