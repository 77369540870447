@font-face{
        font-family: AgreementSignature;
        src: url('./fonts/AgreementSignature-ALx9x.otf');
      }
      *{
        font-family: 'AgreementSignature';
        font-weight: normal;
        font-size: 23px;
      }
      body{
        margin: 0;
      }