.bookingHeader{
    display: flex;
    justify-content: center;
    transform: scale(1.7);
    margin: 55px;
}

  @media screen and (max-width: 480px){
    .bookingHeader{
      margin-top: -3rem;
    }
  }