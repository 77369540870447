.port{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 55px;
    padding-bottom: 9rem;
    gap: 3rem;
}

.portBox{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 300px;
    height: 300px;
    border-radius: 1rem;
    box-shadow: 0 5px 75px 0 rgba(0, 0, 0, .55);
}

.portBoxTextStyle{
    display: flex;
    margin-left: -98%;
    margin-top: 15rem;
    font-size: 1.1rem;
    letter-spacing: 5px;
    font-weight: 600;
}

.h{
    display: flex;
    justify-content: center;
    transform: scale(1.7);
}

.hsub{
    display: flex;
    justify-content: center;
    transform: scale(1.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: x-small;
    margin-top: 1rem;
    margin-bottom: -2rem;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
  }

  .projectLink{
    color: pink;
    background-color: black;
  }

  .projectLink:hover{
    box-shadow: 0 5px 55px 0 rgba(0, 0, 0, .35);
  }

  @media screen and (max-width: 480px){
    .portBoxTextStyle{
      font-size: smaller;
    }
    .portBox{
      margin: 150px;
      margin-bottom: -0.03rem;
    }
    .port{
      padding-bottom: 0rem;
      margin-bottom: 3rem;
      padding-right: 0.9rem;
    }
    .h{
      margin-top: -3rem;
    }
    .hsub{
      margin-bottom: -7rem;
    }
    .allPort{
      padding-bottom: 9rem;
    }
  }