.toggle{
    display: flex;
    justify-content: space-between;
    border: 3px solid #538000a4;
    border-radius: 1rem;
    position: relative;
    padding: 2px;
}

.toggle>*{
    width: 1rem;
    height: 1rem;
    color: #538000a4;
}

.t-button{
    border-radius: 100%;
    background: #538000a4;
    position: absolute;
}
