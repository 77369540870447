.projects{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
    margin-top: -3rem;
}

/* heading */
.projects span:nth-of-type(1){
    color: var(--black);
    font-size: 1.1rem;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.projects span:nth-of-type(2){
    color: #538000a4;
    font-size: 2.5rem;
    font-weight: bold;
}

/* slider */
.projects .swiper{
    overflow: visible!important;
}

.projects-slider{
    margin-top: 3rem;
    width: 100%;
}
.projects-slider .swiper-slide{
    width: 20rem;
}
.projects img{
    width: 14rem;
    filter: drop-shadow(-17px 15px 13px #121212);
    border-radius: 19px; 
}

.projects img:hover{
    box-shadow: 0 5px 75px 0 rgba(0, 0, 0, .15);
}
@media screen and (max-width: 480px){
    .projects{
        margin-bottom: -90px;
    }
}