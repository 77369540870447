.bioBox{
    display: flex;
    flex-direction: column;
    padding: 5%;
    box-shadow: 0 5px 75px 0 rgba(0, 0, 0, .35);
}
.textStyle{
    display: inline-block;
}

.textStyle h1{
    font-size: larger;
}

.textStyle p{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 27px;
    font-weight: 500;
    letter-spacing: 1px;
    font-style: italic;
    font-size: 95%;
}
@media screen and (max-width: 480px){
    .bioBox{
        box-shadow: 0 15px 35px 0 rgba(0, 0, 0, .35);
    }
}
