.floatingDiv{
    justify-content: space-around;
    display: flex;
    background: white;
    box-shadow: var(--boxShadow);
    box-shadow: 0 5px 75px 0 rgba(0, 0, 0, .52);
    border-radius: 17;
    align-items: center;
    padding: 0px 20px 0px 0px;
    height: 4.5rem;
}

.floatingDiv>img{
    transform: scale(0.5);
}

.floatingDiv>img{
    box-shadow: 0 5px 55px 0 rgba(0, 0, 0, .52);
}
.floatingDiv>span{
    font-family: sans-serif;
    font-size: 16px;
}

.floatingDiv:hover{
    transform: scale(1.1);
    box-shadow: 0 5px 75px 0 rgba(0, 0, 0, .52);
}