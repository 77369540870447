.n-wrapper{
    height: 10vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;
}
.n-left{
    flex:1;
    align-items: center;
    display: flex;
    gap: 2rem;
}

.n-name{
    font-size: 1.1rem;
    font-weight: bolder;
    font-size: 40%;
    transform: scale(1.7);

}

.n-right{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    padding-left: 45%;
}

.n-list{
    flex: 10;
}

.n-list>ul{
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    list-style-type: none;
}

a {
    color:rgba(10, 119, 56, 0.685);
    text-decoration: none;
  }

a:hover{
    color: var(--orange)
}



@media screen and (max-width: 480px) {
    .n-list{
      display: none;
    }
    .n-name{
        padding-left: 0.2rem;
    }
  }