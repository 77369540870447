.intro{
    display: flex;
    height: 77vh;
    margin-bottom: 7rem;
}

.intro-left{
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}

.intro-name{
    display: flex;
    flex-direction: column;
}

.intro-name>:nth-child(1){
    color: var(--black);
    font-weight: bold;
    font-size: 2rem;
}

.intro-name>:nth-child(2){
    color: #538000a4;
    font-weight: bold;
    font-size: 3rem;
}

.intro-name>:nth-child(3){
    font-weight: 100;
    font-size: 20px;
    color: var(--gray);
}

.intro-button{
    width: 6rem;
    height: 2rem;
}

.intro-icons{
    margin-top: 3rem;
    display: flex;
    gap: 0rem;
}

.intro-icons>*{
    transform: scale(0.5);
}

.intro-icons>*:hover{
    cursor: pointer;
}

.intro-right{
    flex: 1;
    position: relative;

}

.intro-right>*{
    position: absolute;
    z-index: 1;
    left: 28%;
}

.intro-right>:nth-child(2){
    transform: scale(0.30);
    top: -26%;
    left: -29%;
    border-radius: 50%;
    padding: 0;
}

.blur{
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: "#72a735";
    z-index: -9;
    top: -18%;
    left: 56%;
    filter: blur(102px);
}

.moon:hover{
    transform: scale(1.5);
}
.intro-name{
    display: inline-block;
}
.intro-name h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    border-right: 0.15em solid rgba(9, 255, 0, 0.315) transparent;
    animation: blink 0.7s steps(60, end) 1 alternate, typing 3s steps(25);
    animation-duration: 1.6s;
}

@keyframes blink {
    50%{
        border-color: transparent;
    }
}

@keyframes typing {
    from {
        width: 0%;
    }
}



@media screen and (max-width: 480px) {
    .intro{
      flex-direction: column;
      margin-bottom: 25rem;
      margin-top: -35%;
    }
    .intro-left{
        padding-bottom: 20%;
    }
    .intro-right{
        transform: scale(0.8);
        left: -3rem;
    }
    .intro-name>:nth-child(2){
        white-space: initial;
    }
    .intro-right>:nth-child(2):hover{
        transform: scale(0.2);
    }
    .intro-name h1{
        animation: none;
    }
    .intro-right>:nth-child(4){
        opacity: 0.8;
    }
  }