.footer{
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: 7rem;
}

.f-content{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    gap: 1rem;
    color: white;
}

.f-icons{
    display: flex;
    gap: 1rem;
}

.f-icons>:nth-child(3):hover{
    transform: scale(1.2);
}

.f-icons>:nth-child(4):hover{
    transform: scale(1.2);
}

.f-icons>:nth-child(5):hover{
    transform: scale(1.2);
}

.homeButton{
    display: none;
}

.createdBy{
    display: flex;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: oblique;
    font-size: smaller;
}

.createdByMobileVersion{
    display: none;
}

.bio{
    display: none;
}

@media screen and (max-width: 480px) {
    .footer{
        margin-bottom: -0.9rem;
        margin-top: 8rem;
    }
    .f-icons{
        margin-bottom: 20px;
        transform: scale(0.5);
    }
    .homeButton{
        display: contents;
    }
    .bio{
        display: contents;
    }
    .createdByMobileVersion{
        display: flex;
        align-items: center;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-style: oblique;
        font-size: 1rem;
        transform: scale(0.7);
    }
    .f-content span:nth-child(1){
        display: none;
    }
}