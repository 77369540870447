  .contactSection{
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 5px 75px 0 rgba(0, 0, 0, .35);
  }

  .contactSection span{
    display: flex;
    justify-content: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .contactForm{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left:450px;
    padding-right:450px;
    gap: 0.3rem;
    padding-bottom: 5rem;
  }

  @media screen and (max-width: 480px){
    .contactSection{
      display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 5px 75px 0 rgba(0, 0, 0, .35);
    transform: scale(1);
    }
    .contactForm{
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-left:50px;
      padding-right:50px;
      gap: 0.3rem;
      padding-bottom: 5rem;
    }
  }